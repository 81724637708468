import React, { FC, useMemo, useState, useEffect } from 'react';
import { Pagination as BootstrapPagination } from 'react-bootstrap';

import './Pagination.scss';
import { PaginationProps } from './model';

const Pagination: FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  activePage: activePageProp,
  onPageChange,
}) => {
  const [activePage, setActivePage] = useState(activePageProp);

  useEffect(() => {
    setActivePage(activePageProp);
  }, [activePageProp]);

  const onPageClick = (page: number) => {
    setActivePage(page);
    if (onPageChange) {
      onPageChange(page);
    }
  };

  const totalPages = useMemo(() => Math.ceil(totalItems / itemsPerPage), [
    totalItems,
    itemsPerPage,
  ]);

  return (
    <>
      {totalPages > 0 && (
        <BootstrapPagination data-testid="pagination">
          <BootstrapPagination.Prev
            className="pagination__item"
            disabled={activePage <= 1}
            onClick={() => onPageClick(activePage - 1)}
          />
          {Array.from(Array(totalPages)).map((_value, index) => {
            const currentPage = index + 1;

            return (
              <BootstrapPagination.Item
                className="pagination__item"
                active={activePage === currentPage}
                key={currentPage}
                onClick={() => onPageClick(currentPage)}
              >
                {currentPage}
              </BootstrapPagination.Item>
            );
          })}
          <BootstrapPagination.Next
            className="pagination__item"
            onClick={() => onPageClick(activePage + 1)}
            disabled={activePage >= totalPages}
          />
        </BootstrapPagination>
      )}
    </>
  );
};

export default Pagination;
