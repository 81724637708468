import React, { FC, useEffect, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { graphql, navigate } from 'gatsby';

import Layout from 'components/Layout';
import Container from 'react-bootstrap/Container';
import SearchResults from 'components/SearchResults';
import { isBrowser } from 'utils/browser';
import Pagination from 'components/common/Pagination/Pagination';

import getQueryParamByName from 'utils/getQueryParamByName';
import { SearchResultItemProps } from 'components/SearchResults/SearchResultItem/models';
import { SearchResultPageProps } from './models';

const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID as string,
  process.env.GATSBY_ALGOLIA_PUBLIC_KEY as string
) as any;

const index = client.initIndex(process.env.GATSBY_ALGOLIA_INDEX);
const ITEMS_PER_PAGE = 10;

const SearchResultPage: FC<SearchResultPageProps> = ({
  pageContext: {
    breadcrumb: { crumbs },
  },
  data: { searchResults: data },
}) => {
  const crumbsSettings = {
    crumbs,
  };
  const {
    searchResultLinkText,
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
    notFoundText,
    foundText,
  } = data;
  const searchPage = (isBrowser() && getQueryParamByName('page')) || '1';
  const searchQuery = (isBrowser() && getQueryParamByName('query')) || '';

  const [currentPage, setCurrentPage] = useState(parseInt(searchPage, 10));
  const [searchResults, setSearchResults] = useState<{
    combined: SearchResultItemProps[];
    query: string;
    total: number;
  }>({
    combined: [],
    query: '',
    total: 0,
  });

  const onPageChange = (page) => {
    setCurrentPage(page);
    navigate(`/search-results/?query=${searchQuery}&page=${page}`);
  };

  useEffect(() => {
    if (searchQuery) {
      const pageQueryNumber = parseInt(getQueryParamByName('page') || '1', 10);

      index
        .search(searchQuery, {
          page: pageQueryNumber - 1,
          hitsPerPage: ITEMS_PER_PAGE,
        })
        .then((search) => {
          setSearchResults({
            combined: search.hits,
            query: searchQuery,
            total: search.nbHits,
          });
        });
    }
  }, [searchQuery]);

  return (
    <Layout
      crumbsSettings={crumbsSettings}
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
      }}
    >
      <Container fluid>
        <SearchResults
          searchResults={searchResults.combined}
          buttonText={searchResultLinkText}
          phrase={searchResults.query}
          notFoundText={notFoundText}
          foundText={foundText}
          totalItems={searchResults.total}
        />
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE}
          onPageChange={onPageChange}
          totalItems={searchResults.total}
          activePage={currentPage}
        />
      </Container>
    </Layout>
  );
};

export default SearchResultPage;

export const query = graphql`
  {
    searchResults {
      title
      notFoundText
      foundText
      searchResultLinkText
      seoMetaDescription
      seoMetaTitle
      seoMetaKeywords
    }
  }
`;
