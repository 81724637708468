/**
 * This function allows to insert variables into string. String should be in format "{0} text {1} text", where {0} and {1} will be replaced with data from variables parameter.
 * @param text String in format "{0} text {1} text"
 * @param variables List of variables which will be inserted to string
 */
const fillWithVariables = (text: string, variables: string[]) =>
  text?.replace(/{([^}]*)}/g, (_foundPlaceholder, index) => variables[index]);

// eslint-disable-next-line import/prefer-default-export
export { fillWithVariables };
