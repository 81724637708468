import React, { FC } from 'react';
import { Link } from 'gatsby';

import { SearchResultItemProps } from './models';
import truncateText from './utils';
import './styles.scss';

export const SearchResultItem: FC<SearchResultItemProps> = ({
  name,
  description,
  url,
  buttonText,
}) => (
  <li className="search-result">
    <div className="search-result__wrapper">
      <Link className="search-result__title" to={url} aria-label={name}>
        {name}
      </Link>
      <p className="search-result__description">{truncateText(description)}</p>
      <Link className="search-result__link" to={url}>
        {buttonText}
      </Link>
    </div>
  </li>
);

export default SearchResultItem;
